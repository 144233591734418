<!--
 * @Author: Dyf
 * @Date: 2023-02-18 18:24:03
 * @LastEditors: Dyf
 * @LastEditTime: 2023-02-28 21:23:38
 * @Description: 课程管理
-->
<style lang="scss" scoped>
.page-lesson {
    width: 100%;
    height: 100%;
}
</style>
<template>
    <section class="page-lesson">
        <!-- 需要缓存的页面 -->
        <keep-alive>
            <router-view :key="key" v-if="$route.meta.keepAlive" />
        </keep-alive>
        <!-- 不用缓存的页面 -->
        <router-view :key="key" v-if="!$route.meta.keepAlive" />
    </section>
</template>
  
<script>
export default {
    name: "lesson",
    computed: {
        key() {
            return this.$route.path;
        },
    },
    /** 监听页面路由改变 */
    beforeRouteUpdate(to, from, next) {
        this.$destroy();
        to.matched[1].meta.title = to.params.class;
        if(to.matched[2].name == 'LESSON_MANAGE') to.matched[2].meta.title = to.matched[1].meta.title
        next();
    },
};
</script>
  
